<template>
  <div class="ReconciliationMobile">
    <div v-for="(item, index) in data" :key="index">
      <div class="indent" v-if="item.Keys == 'text'" v-html="item.value"></div>
      <div
        v-else-if="item.Keys == 'Title'"
        :style="{
          textAlign: item.textAlign,
          fontSize: item.fontSize,
          lineHeight: item.lineHeight + 'px',
          fontWeight: item.bold,
        }"
      >
        {{ item.value }}
      </div>
      <div v-else-if="item.Keys == 'CustomerName'">{{ item.value }}</div>
      <div v-else-if="item.Keys == 'Time'">
        {{ entity.CreateTime.substring(0, 10) }}
      </div>
    </div>
    <div class="seal" v-if="entity.CompanySeal || entity.CustomerSeal">
      <div v-if="entity.CompanySeal">
        <img :src="entity.CompanySeal" alt="公司签章" />
      </div>
      <div v-if="entity.CustomerSeal">
        <img :src="entity.CustomerSeal" alt="客户签章" />
      </div>
    </div>
    <div style="height: 60px" v-if="$route.query.show"></div>
    <div class="buttonGroup" v-if="$route.query.show">
      <el-button v-show="entity.State == 1" type="primary" disabled
        >已完成 对符</el-button
      >
      <el-button v-show="entity.State == 2" type="danger" disabled
        >已完成 不符</el-button
      >
      <el-button v-if="entity.State == 0" type="primary" @click="submit(1)"
        >对符</el-button
      >
      <el-button v-if="entity.State == 0" type="danger" @click="submit(2)"
        >不符</el-button
      >
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      title="对账函密码"
      :visible.sync="dialogVisible"
      width="300px"
    >
      <el-input placeholder="请输入对账函密码" v-model="LetterCode"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="loading" type="primary" @click="handleClose"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      data: [],
      entity: {},
      dialogVisible: false,
      loading: false,
      LetterCode: "",
    };
  },
  async mounted() {
    await this.getFormData();
    await this.getShareInfo();
  },
  methods: {
    // 对符不符
    submit(bl) {
      this.$http
        .post(
          "/Base/Base_Reconciliation/SubmitLetter?Id=" +
            this.entity.Id +
            "&State=" +
            bl
        )
        .then((res) => {
          if (res.Success) {
            this.$message.success("操作成功");
            this.getFormData();
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    // 验证授权码
    handleClose() {
      this.loading = true;
      this.$http
        .post(
          "/Base/Base_Reconciliation/IntendedCode?Id=" +
            this.entity.Id +
            "&LetterCode=" +
            this.LetterCode
        )
        .then((res) => {
          this.loading = false;
          if (res.Success && res.Data) {
            this.dialogVisible = false;
          } else {
            this.$message.error("授权码错误");
          }
        });
    },
    // 是否需要授权码
    async getPassStatus() {
      await this.$http
        .post("/Base/Base_Reconciliation/IsEnter?Id=" + this.entity.Id)
        .then((res) => {
          if (res.Success) {
            this.dialogVisible = !res.Data;
          } else this.dialogVisible = true;
        });
    },
    // 获取表单数据
    async getFormData() {
      await this.$http
        .post(
          "/Base/Base_Reconciliation/GetRecDetail?Id=" + this.$route.query.Id
        )
        .then((res) => {
          if (res.Success) {
            this.entity = res.Data;
            // 按照高低排序
            this.data = JSON.parse(this.entity.SpanContent).printlist.sort(
              (a, b) => a.y - b.y
            );
            console.log(this.entity);
            console.log(this.data);
            if (this.entity.State == 0) this.getPassStatus();
          }
        });
    },
    // 获取微信认证
    async getShareInfo() {
      let url = location.href.split("#")[0]; // 只需要传地址就好，其他后端都会写好来
      let ticketData = await this.$http.get(
        "/BO/BillList/GetWxSign?AppType=AppShq&url=" + url
      );
      let arr = ticketData.Data.split(",");
      const config = {
        appId: "wx2ba9027e237ff635",
        timestamp: arr[2],
        nonceStr: arr[1],
        signature: arr[0],
      };
      wx.config({
        debug: false, //是否打开debug调试模式。
        appId: config.appId, // appID 公众号的唯一标识
        timestamp: config.timestamp, // 生成签名的时间戳
        nonceStr: config.nonceStr, //  生成签名的随机串
        signature: config.signature, // 生成的签名
        jsApiList: ["onMenuShareAppMessage"],
      });
      wx.ready(() => {
        var shareData = {
          title: "对账函",
          desc: this.entity.CompanyName + "发给您的对账函",
          link: location.href + "&show=true",
          imgUrl: "https://hzq.yoojet.com/logo200.png",
          success: function (res) {
            console.log("分享好友成功");
          },
          cancel: function (err) {
            console.log(err, "错误信息");
          },
        };
        //点击要去分享
        wx.onMenuShareAppMessage(shareData);
      });
    },
  },
};
</script>

<style lang="less">
.ReconciliationMobile {
  padding: 10px;
  .indent {
    text-indent: 2em;
    margin-bottom: 10px;
  }
  .a {
    padding: 0 20px;
    border-bottom: 1px solid #000;
  }
  .seal {
    margin-top: 20px;
    display: flex;
    div {
      flex: 1;
      img {
        width: 150px;
      }
    }
  }
  .buttonGroup {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: right;
    padding: 10px 20px;
  }
}
</style>